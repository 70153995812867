import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import NotFound from './components/NotFound.vue'
import Login from './views/Login.vue'
import {userService} from '@/store/auth-header.js'
import { store } from '@/store/store.js'
Vue.use(Router)

//https://stackoverflow.com/questions/64298276/load-dynamic-vue-routes-from-json-file
//https://router.vuejs.org/api/#router-addroutes

// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     // this route requires auth, check if logged in
//     // if not, redirect to login page.
//     if (!store.getters.isLoggedIn) {
//       next({ name: 'Login' })
//     } else {
//       next() // go to wherever I'm going
//     }
//   } else {
//     next() // does not require auth, make sure to always call next()!
//   }
// });

export default new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    }, {
      path: '/login',
      name: 'login',
      component: Login
    },{
      path: '/ledgergroup',
      name: 'ledgergroup',
      component: () => import('./views/account/ledgergroup/LedgerGroupView.vue')
    },
    {
      path: '/ledger',
      name: 'ledger',
      component: () => import('./views/account/ledger/LedgerView.vue')
    },{
      path: '/about',
      name: 'about',
      component: () => import('./views/About.vue')
    },{
      path: '/newnote',
      name: 'newnote',
      component: () => import('@/components/v1/note/NoteForm.vue')
    },{
      path: '/opennote',
      name: 'opennote',
      component: () => import('./views/tms/office/note/NoteView.vue')
    },{
      path: '/supplier',
      name: 'supplier',
      component: () => import('./views/tms/master/supplier/SupplierView.vue')
    },{
      path: '/agent',
      name: 'agent',
      component: () => import('./views/tms/master/agent/AgentView.vue')
    },{
      path: '/buyer',
      name: 'buyer',
      component: () => import('./views/tms/master/buyer/BuyerView.vue')
    },{
      path: '/jobworker',
      name: 'jobworker',
      component: () => import('./views/tex/jobworker/JobworkerView.vue')
    },{
      path: '/serviceprovider',
      name: 'serviceprovider',
      component: () => import('./views/tex/serviceprovider/ServiceProviderView.vue')
    },{
      path: '/itemgroup',
      name: 'itemgroup',
      component: () => import('./views/tms/master/itemgroup/ItemGroupView.vue')
    }, {
      path: '/componentsgroup',
      name: 'componentsgroup',
      component: () => import('./views/tms/master/componentgroup/ComponentGroupView.vue')
    },{
      path: '/components',
      name: 'components',
      component: () => import('./views/tms/master/component/ComponentView.vue')

    }, {
      path: '/itemcategory',
      name: 'itemcategory',
      component: () => import('./views/tms/master/category/CategoryView.vue')
    }, {
      path: '/itembrand',
      name: 'itembrand',
      component: () => import('./views/tms/master/brand/BrandView.vue')
    }, {
      path: '/item',
      name: 'item',
      component: () => import('./views/tms/master/item/ItemView.vue')
    }, {
      path: '/terms',
      name: 'terms',
      component: () => import('./views/tms/master/terms/TermsView.vue')
    },{
      path: '/project',
      name: 'project',
      component: () => import('./views/tms/master/project/ProjectView.vue')
    },{
      path: '/designation',
      name: 'designation',
      component: () => import('./views/tms/master/designation/DesignationView.vue')
    },{
      path: '/newinvoice',
      name: 'newinvoice',
      component: () => import('@/views/tms/vouchers/invoice/InvoiceForm.vue')
    },{
      path: '/openinvoice',
      name: 'openinvoice',
      component: () => import('./views/tms/vouchers/invoice/InvoiceView.vue')
    },{
      path: '/openinvoicepayment',
      name: 'openinvoicepayment',
      component: () => import('./views/tms/vouchers/invoice/PurchasePaymentView.vue')

    },{
      path: '/hsninvoice',
      name: 'hsninvoice',
      component: () => import('@/views/tms/vouchers/invoice/HsnInvoiceForm.vue')
    },{
      path: '/openhsninvoice',
      name: 'openhsninvoice',
      component: () => import('./views/tms/vouchers/invoice/HsnInvoiceView.vue')
    },{
      path: '/newsaleinvoice',
      name: 'newsaleinvoice',
      component: () => import('./views/tms/vouchers/saleinvoice/SaleInvoiceForm.vue')
    }, {
      path: '/opensaleinvoice',
      name: 'opensaleinvoice',
      component: () => import('./views/tms/vouchers/saleinvoice/SaleInvoiceView.vue')
    },{
      path: '/opensalereceipt',
      name: 'opensalereceipt',
      component: () => import('./views/tms/vouchers/saleinvoice/SaleReceiptView.vue')
    },{
      path: '/newcashpayment',
      name: 'newcashpayment',
      component: () => import('./views/account/cashpayment/CashPaymentForm')
    },{
      path: '/opencashpayment',
      name: 'opencashpayment',
      component: () => import('./views/account/cashpayment/CashPaymentView.vue')
    },{
      path: '/newjournal',
      name: 'newjournal',
      component: () => import('./views/account/journal/JournalForm.vue')
    },{
      path: '/openjournal',
      name: 'openjournal',
      component: () => import('./views/account/journal/JournalView.vue')
    },{
      path: '/newpayment',
      name: 'newpayment',
      component: () => import('./views/account/payment/PaymentForm.vue')
    },{
      path: '/openpayment',
      name: 'openpayment',
      component: () => import('./views/account/payment/PaymentView.vue')
    },{
      path: '/newreceipt',
      name: 'newreceipt',
      component: () => import('./views/account/receipt/ReceiptForm.vue')
    },{
      path: '/openreceipt',
      name: 'openreceipt',
      component: () => import('./views/account/receipt/ReceiptView.vue')
    },{
      path: '/newcontra',
      name: 'newcontra',
      component: () => import('./views/account/contra/ContraForm.vue')
    },{
      path: '/opencontra',
      name: 'opencontra',
      component: () => import('./views/account/contra/ContraView.vue')
    },{
      path: '/newindent',
      name: 'indent',
      component: () => import('./components/v1/indent/IndentForm.vue')
    },{
      path: '/openindent',
      name: 'openindent',
      component: () => import('./views/tms/office/indent/IndentView.vue')
    },{
      path: '/newbudget',
      name: 'newbudget',
      component: () => import('@/components/v1/budget/BudgetForm.vue')
    },{
      path: '/openbudget',
      name: 'openbudget',
      component: () => import('./views/tms/office/budget/BudgetView.vue')
    },{
      path: '/newquote',
      name: 'newquote',
      component: () => import('@/components/v1/quote/QuotationForm.vue')
    },{
      path: '/openquote',
      name: 'openquote',
      component: () => import('./views/tms/office/quote/QuotationView.vue')
    },{
      path: '/count',
      name: 'count',
      component: () => import('./views/tex/count/CountView.vue')
    },{
      path: '/yarn',
      name: 'yarn',
      component: () => import('./views/tex/yarn/YarnView.vue')
    },{
      path: '/process',
      name: 'process',
      // component: () => import('@/components/v1/tms/process/ProcessForm.vue')
      component: () => import('./views/tex/process/ProcessView.vue')

    },{
      path: '/article',
      name: 'article',
      component: () => import('./views/tex/article/ArticleView.vue')
    },{
      path: '/group',
      name: 'group',
      component: () => import('./views/tex/group/GroupView.vue')
    },{
      path: '/design',
      name: 'design',
      component: () => import('./views/tex/design/DesignView.vue')
    },{
      path: '/color',
      name: 'color',
      component: () => import('./views/tex/color/ColorView.vue')
    },{
      path: '/quality',
      name: 'quality',
      component: () => import('./views/tex/quality/QualityView.vue')
    },{
      path: '/style',
      name: 'style',
      component: () => import('./views/tex/style/StyleView.vue')
    },{
      path: '/size',
      name: 'size',
      component: () => import('./views/tex/size/SizeView.vue')
    },{
      path: '/fabric',
      name: 'fabric',
      component: () => import('./views/tex/fabric/FabricView.vue')
    },{
      path: '/processedfabric',
      name: 'processedfabric',
      component: () => import('./views/tex/fabric/ProcessedFabricView.vue')

    }, {
      path: '/madeup',
      name: 'madeup',
      component: () => import('./views/tex/madeup/MadeUpView.vue')
    },{
      path: '/processedmadeup',
      name: 'processedmadeup',
      component: () => import('./views/tex/madeup/ProcessedMadeupView.vue')
    },{
      path: '/new_jobwork_workorder',
      name: 'new_jobwork_workorder',
      component: () => import('@/views/fms/workorder/WorkOrderForm.vue')
    },{
      path: '/open_jobwork_workorder',
      name: 'open_jobwork_workorder',
      component: () => import('./views/fms/workorder/WorkOrderView.vue')
    }, {
      path: '/new_jobwork_delivery',
      name: 'new_jobwork_delivery',
      component: () => import('@/views/fms/jobworkdelivery/JobworkDeliveryForm.vue')
    },{
      path: '/open_jobwork_delivery',
      name: 'open_jobwork_delivery',
      component: () => import('@/views/fms/jobworkdelivery/JobworkDeliveryView.vue')
    },{
      path: '/new_jobwork_lotreceipt',
      name: 'jobworklotreceipt',
      component: () => import('@/views/fms/jobworklotreceipt/JobworkLotReceipt_form.vue')
    },{
      path: '/open_jobwork_lotreceipt',
      name: 'open_jobwork_lotreceipt',
      component: () => import('@/views/fms/jobworklotreceipt/jobworklotreceiptview.vue')
    },{
      path: '/new_jobwork_receipt',
      name: 'new_jobwork_receipt',
      component: () => import('@/views/fms/jobworkreceipt/JobworkReceiptForm.vue')
    }, {
      path: '/open_jobwork_receipt',
      name: 'open_jobwork_receipt',
      component: () => import('@/views/fms/jobworkreceipt/JobworkReceiptView.vue')
    }, {
      path: '/new_jobwork_invoice',
      name: 'new_jobwork_invoice',
      component: () => import('@/components/v1/tms/jobworkinvoice/JobworkInvoiceForm.vue')
    }, {
      path: '/new_rawmaterial_return',
      name: 'new_rawmaterial_return',
      component: () => import('@/views/fms/jobworkrawmaterialreceipt/JobworkRawmaterialReceiptForm.vue')
    }, {
      path: '/open_rawmaterial_return',
      name: 'open_rawmaterial_return',
      component: () => import('@/views/fms/jobworkrawmaterialreceipt/JobworkRawmaterialReceiptView.vue')
    }, {
      path: '/new_receipt_return',
      name: 'new_receipt_return',
      component: () => import('@/views/fms/jobworkreceiptreturn/JobworkReceiptReturnForm.vue')
    }, {
      path: '/open_receipt_return',
      name: 'open_receipt_return',
      component: () => import('@/views/fms/jobworkreceiptreturn/JobworkReceiptReturnView.vue')
    },{
      path: '/new_production_delivery',
      name: 'new_production_delivery',
      component: () => import('@/components/v1/tms/productiondelivery/ProductionDeliveryForm.vue')
    },{
      path: '/open_production_delivery',
      name: 'open_production_delivery',
      component: () => import('./views/tex/productiondelivery/ProductionDeliveryView.vue')
    }, {
      path: '/new_production_receipt',
      name: 'new_production_receipt',
      component: () => import('@/components/v1/tms/productionreceipt/ProductionReceiptForm.vue')
    },{
      path: '/open_production_receipt',
      name: 'open_production_receipt',
      component: () => import('./views/tex/productionreceipt/ProductionReceiptView.vue')
    },{
      path: '/machine',
      name: 'machine',
      component: () => import('./views/fms/machine/MachineView.vue')
    },{
      path: '/defect',
      name: 'defect',
      component: () => import('./views/tex/defect/DefectView.vue')
    },{
      path: '/container',
      name: 'container',
      component: () => import('./views/fms/container/ContainerView.vue')
    },{
      path: '/fms_hdpe',
      name: 'fms_hdpe',
      component: () => import('./views/fms/hdpe/HdpeView.vue')
    },{
      path: '/fms_color',
      name: 'fms_color',
      component: () => import('./views/fms/colorchart/ColorChartView.vue')
    },{
      path: '/fms_quality',
      name: 'fms_quality',
      component: () => import('./views/fms/quality/QualityView.vue')
    },{
      path: '/fms_pigment',
      name: 'fms_pigment',
      component: () => import('./views/fms/pigment/PigmentView.vue')
    },{
      path: '/fms_uv',
      name: 'fms_uv',
      component: () => import('./views/fms/uv/UvView.vue')
    },{
      path: '/fms_zincpowder',
      name: 'fms_zincpowder',
      component: () => import('./views/fms/zinc/ZincView.vue')
    },{
      path: '/fms_masterbatch',
      name: 'fms_masterbatch',
      component: () => import('./views/fms/masterbatch/MasterBatchView.vue')
    },{
      path: '/fms_beam',
      name: 'fms_beam',
      component: () => import('./views/fms/beam/BeamView.vue')
    },{
      path: '/fms_cone',
      name: 'fms_cone',
      component: () => import('./views/fms/cone/ConeView.vue')
    },{
      path: '/fms_fabric',
      name: 'fms_fabric',
      component: () => import('./views/fms/fabric/FabricView.vue')
    },{
      path: '/new_extrusion_delivery',
      name: 'new_extrusion_delivery',
      component: () => import('@/views/fms/extrusion/ExtrusionDeliveryForm.vue')
    },{
      path: '/open_extrusion_delivery',
      name: 'open_extrusion_delivery',
      component: () => import('@/views/fms/extrusion/ExtrusionDeliveryView.vue')
    }, {
      path: '/new_extrusion_receipt',
      name: 'new_extrusion_receipt',
      component: () => import('@/views/fms/extrusion/ExtrusionReceiptForm.vue')
    },{
      path: '/open_extrusion_receipt',
      name: 'open_extrusion_receipt',
      component: () => import('@/views/fms/extrusion/ExtrusionReceiptView.vue')
    },{
      path: '/new_warp_delivery',
      name: 'warp_delivery',
      component: () => import('@/views/fms/warping/WarpingDeliveryForm.vue')
    },{
      path: '/open_warp_delivery',
      name: 'open_warp_delivery',
      component: () => import('@/views/fms/warping/WarpingDeliveryView.vue')
    },{
      path: '/new_warp_receipt',
      name: 'warp_receipt',
      component: () => import('@/views/fms/warping/WarpingReceiptForm.vue')
    },{
      path: '/open_warp_receipt',
      name: 'open_warp_receipt',
      component: () => import('@/views/fms/warping/WarpingReceiptView.vue')
    },{
      path: '/new_knitting_delivery',
      name: 'new_knitting_delivery',
      component: () => import('@/views/fms/knitting/KnittingDeliveryForm.vue')
    },{
      path: '/open_knitting_delivery',
      name: 'open_knitting_delivery',
      component: () => import('./views/fms/knitting/KnittingDeliveryView.vue')
    },{
      path: '/new_knitting_receipt',
      name: 'new_knitting_receipt',
      component: () => import('@/views/fms/knitting/KnittingReceiptForm.vue')
    }, {
      path: '/open_knitting_receipt',
      name: 'open_knitting_receipt',
      component: () => import('./views/fms/knitting/KnittingReceiptView.vue')
    },{
      path: '/new_knitting_qc',
      name: 'new_knitting_qc',
      component: () => import('@/views/fms/knitting/KnittingQcForm.vue')
    },{
      path: '/open_knitting_qc',
      name: 'open_knitting_qc',
      component: () => import('@/views/fms/knitting/KnittingQcView.vue')
    },{
      path: '/new_container_reset',
      name: 'new_container_reset',
      component: () => import('@/views/fms/containerreset/ContainerResetForm.vue')
    }, {
      path: '/open_container_reset',
      name: 'open_container_reset',
      component: () => import('@/views/fms/containerreset/ContainerResetView.vue')
    },{
      path: '/new_stentering',
      name: 'new_stentering',
      component: () => import('@/views/fms/stentering/StenteringForm.vue')
    }, {
      path: '/open_stentering',
      name: 'open_stentering',
      component: () => import('@/views/fms/stentering/StenteringView.vue')
    },{
      path: '/new_stenteringqc',
      name: 'new_stenteringqc',
      component: () => import('@/views/fms/stentering/StenteringQcForm.vue')
    }, {
      path: '/open_stenteringqc',
      name: 'open_stenteringqc',
      component: () => import('@/views/fms/stentering/StenteringQcView.vue')
    },{
      path: '/newstitching',
      name: 'newstitching',
      component: () => import('@/views/fms/stitching/StitchingForm.vue')
    }, {
      path: '/openstitching',
      name: 'openstitching',
      component: () => import('@/views/fms/stitching/StitchingView.vue')
    },{
      path: '/new_rolling',
      name: 'new_rolling',
      component: () => import('@/views/fms/rolling/RollingForm.vue')
    }, {
      path: '/open_rolling',
      name: 'open_rolling',
      component: () => import('@/views/fms/rolling/RollingView.vue')

    },{
      path: '/newsaleorder',
      name: 'newsaleorder',
      component: () => import('@/views/fms/saleorder/SaleOrderForm')
    }, {
      path: '/opensaleorder',
      name: 'opensaleorder',
      component: () => import('@/views/fms/saleorder/SaleOrderView')
    }, {
      path: '/peningsaleorders',
      name: 'peningsaleorders',
      component: () => import('@/views/fms/saleorder/SaleOrderBalanceView')
    },{
      path: '/newbale',
      name: 'newbale',
      component: () => import('@/views/fms/bale/BaleForm.vue')
      // component: () => import('./views/account/contra/ContraForm.vue')
    }, {
      path: '/openbale',
      name: 'openbale',
      component: () => import('@/views/fms/bale/BaleView')
    },{
      path: '/status',
      name: 'status',
      component: () => import('./views/tms/reports/status/StatusView.vue')
    }, {
      path: '/stockreport',
      name: 'stockreport',
      component: () => import('./views/tms/reports/stock/StockReportView.vue')
    }, {
      path: '/daybook_display',
      name: 'daybook_display',
      component: () => import('./views/reports/accounts/DayBookView.vue')
    }, {
      path: '/ledger_display',
      name: 'ledger_display',
      component: () => import('./views/reports/accounts/LedgerBookView.vue')
    }, {
      path: '/group_summary_display',
      name: 'group_summary_display',
      component: () => import('./views/reports/accounts/GroupSummaryView.vue')
    }, {
      path: '/tds_summary_display',
      name: 'tds_summary_display',
      component: () => import('./views/reports/accounts/PurchasePayTdsView.vue')
    },{
      path: '/trailbalance',
      name: 'trailbalance',
      component: () => import('./views/reports/accounts/TrialBalanceView.vue')
    },{
      path: '/balancesheet',
      name: 'balancesheet',
      component: () => import('./views/reports/accounts/BalanceSheetView.vue')
    },{
      path: '/pandl',
      name: 'pandl',
      component: () => import('./views/reports/accounts/ProfitandLossView.vue')
    },{
      path: '/workorder_status',
      name: 'workorder_status',
      component: () => import('./views/reports/workorder/WorkOrderStatusView.vue')
    },{
      path: '/producthistory',
      name: 'producthistory',
      component: () => import('./views/tms/reports/history/StockHistoryView.vue')
    },{
      path: '/gstportal',
      name: 'gstportal',
      component: () => import('./views/tools/gst/GstPortalView.vue')
    }, {
      path: '/hr-designation',
      name: 'hr-designation',
      component: () => import('./views/hr/designation/DesignationView.vue')
    }, {
      path: '/hr-emp',
      name: 'hr-emp',
      component: () => import('./views/hr/employee/EmployeeView.vue')
    },{
      path: '/hr-shift',
      name: 'hr-shift',
      component: () => import('@/components/v1/hr/shift/ShiftForm.vue')
    },{
      path: '/gst3b',
      name: 'gst3b',
      component: () => import('@/views/reports/gst/gst3bview.vue')
    }, {
      path: '/gst3bdetailed',
      name: 'gst3bdetailed',
      component: () => import('@/views/reports/gst/gst3bdetailed.vue')
    }, {
      path: '/formdesigner',
      name: 'formdesigner',
      component: () => import('@/views/tools/designer/FormDesigner.vue')
    }, {
      path: '/rep_extrusion',
      name: 'rep_extrusion',
      component: () => import('@/views/reports/extrusion/ExtrusionReport.vue')
    },{
      path: '/rep_extrusionday',
      name: 'rep_extrusionday',
      component: () => import('@/views/reports/extrusion/ExtrusionDayReport.vue')
    },{
      path: '/stitched_nets_production',
      name: 'stitched_nets_production',
      component: () => import('@/views/reports/stitching/StitchingReport.vue')
    },{
      path: '/rep_containerstock',
      name: 'rep_containerstock',
      component: () => import('@/views/reports/containers/ContainerReport.vue')
    },{
      path: '/rep_warping',
      name: 'rep_warping',
      component: () => import('@/views/reports/warping/WarpingReport.vue')
    },{
      path: '/rep_knitting',
      name: 'rep_knitting',
      component: () => import('@/views/reports/knitting/KnittingReport.vue')
    },{
      path: '/yarn_stock_report',
      name: 'yarn_stock_report',
      component: () => import('@/views/reports/general/yarn_stock_report.vue')
    },{
      path: '/rawmaterialstock',
      name: 'rawmaterialstock',
      component: () => import('@/views/reports/general/rawmaterial_stock_report.vue')
    },{
      path: '/knit_rawfabricstock',
      name: 'knit_rawfabricstock',
      component: () => import('@/views/reports/general/knit_raw_stock_report.vue')
    },{
      path: '/knit_raw_qc_fabricstock',
      name: 'knit_raw_qc_fabricstock',
      component: () => import('@/views/reports/general/knit_raw_qc_stock_report.vue')
    },{
      path: '/knitfabricstock',
      name: 'knitfabricstock',
      component: () => import('@/views/reports/general/knit_stock_report.vue')
    },{
      path: '/knit_stentering_stock',
      name: 'knit_stentering_stock',
      component: () => import('@/views/reports/general/knit_stentering_stock_report.vue')
    },{
      path: '/knit_stentering_qc_stock',
      name: 'knit_stentering_qc_stock',
      component: () => import('@/views/reports/general/knit_stentering_qc_stock_report.vue')
    }, {
      path: '/rollstock',
      name: 'rollstock',
      component: () => import('@/views/reports/general/roll_stock.vue')
    }, {
      path: '/balestock',
      name: 'balestock',
      component: () => import('@/views/reports/general/bale_stock.vue')
    },{
      path: '/stitched_nets_stock',
      name: 'stitched_nets_stock',
      component: () => import('@/views/reports/general/stitched_nets_stock.vue')
    },{
      path: '/knitting_qc_report',
      name: 'knitting_qc_report',
      component: () => import('@/views/reports/qc/knitting_qc.vue')
    },{
      path: '/recon_raw_material',
      name: 'recon_raw_material',
      component: () => import('@/views/fms/reconciliation/raw_material.vue')
    },{
      path: '/recon_yarn',
      name: 'recon_yarn',
      component: () => import('@/views/fms/reconciliation/yarn.vue')
    // },{
    //   path: '/recon_knitfabric',
    //   name: 'recon_knitfabric',
    //   component: () => import('@/views/fms/reconciliation/knit_fabric.vue')
    },{
      path: '/recon_knitraw',
      name: 'recon_knitraw',
      component: () => import('@/views/fms/reconciliation/knit_raw_fabric.vue')
    },{
      path: '/recon_knitraw_qc',
      name: 'recon_knitraw_qc',
      component: () => import('@/views/fms/reconciliation/knit_raw_qc.vue')
    },{
      path: '/recon_stentering',
      name: 'recon_stentering',
      component: () => import('@/views/fms/reconciliation/knit_stentering.vue')
    },{
      path: '/recon_stentering_qc',
      name: 'recon_stentering_qc',
      component: () => import('@/views/fms/reconciliation/knit_stentering_qc.vue')
    },{
      path: '/stentering_qc_report',
      name: 'stentering_qc_report',
      component: () => import('@/views/reports/qc/stentering_qc.vue')
    },{
      path: '/knitfabric_production',
      name: 'knitfabric_production',
      component: () => import('@/views/reports/production/knittedfabric.vue')
    },{
      path: '/yarn_production',
      name: 'yarn_production',
      component: () => import('@/views/reports/production/yarn.vue')
    }, {
      path: '/companies',
      name: 'companies',
      component: () => import('./views/fms/company/CompanyView.vue')
    },{
      path: '/reportdesigner',
      name: 'reportdesigner',
      component: () => import('@/views/tools/designer/ReportDesigner.vue')
    // },{
    //   path: '/reportviewer',
    //   name: 'reportviewer',
    //   component: () => import('@/views/tools/report/ReportViewer.vue')
    },{
      path: '/gen-settings',
      name: 'Settings',
      component: () => import('@/views/tools/general/SettingsView.vue')

    },{
      path: '/openrawmaterial',
      name: 'openrawmaterial',
      component: () => import('@/views/asquare/rawmaterials/RawMaterialView.vue')
    },{
      path: '/openreceipts',
      name: 'openreceipts',
      component: () => import('@/views/asquare/receipt/ReceiptView.vue')
    },{
      path: '/openproject',
      name: 'openproject',
      component: () => import('@/views/asquare/project/ProjectView.vue')
    },{
      path: '/openlabours',
      name: 'openlabours',
      component: () => import('@/views/asquare/labour/LabourView.vue')
    },{
      path: '/newbill',
      name: 'newbill',
      component: () => import('@/views/asquare/bill/BillForm.vue')
    },{
      path: '/newsupplier',
      name: 'newsupplier',
      component: () => import('@/views/asquare/supplier/SupplierForm.vue')
    },{
      path: '/opensuppliers',
      name: 'opensuppliers',
      component: () => import('@/views/asquare/supplier/SupplierView.vue')
    },{
      path: '/*',
      name: 'not found',
      component: NotFound,
    }
  ],
  base: '/',
  fallback: true,
})
