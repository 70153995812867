import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    // user: {"token":"","id":0,"name":"","finyear":0,"working_date":"2000-01-01","from":"2000-01-01","upto":"2000-01-01","company":{"id":0,"type":0,"status":"Active","name":"","street":"","city":"","pin":"","gstin":"","tel":"","mobile":"","email":"","web":""}},
    user: {"token":"","id":0,"name":"","finyear":2000,"working_date":"","from":"","upto":"","company":{"id":0,"name":"","street":"","city":"","pin":"","gstin":"","tel":"","mobile":"","email":"","web":"","state_cd":1,"landmark":""},"branch":{"id":0,"name":"","street":"","city":"","pin":"","gstin":"","tel":"","mobile":"","email":"","web":"","state_cd":1,"cmp_id":1,"landmark":"","company":{"id":1,"name":"","street":"","city":"","pin":"","gstin":"","tel":"","mobile":"","email":"","web":"","state_cd":1,"landmark":""}}},
    token: '',
  },
  actions: {
    login () {
      console.log('store->state->login');
    }
  },
  mutations: {
    change(state, user) {
      console.log('change');
      state.user = user
    }
  },
  getters: {
    user: state => state.user
  }
})
